import data_categories from '../options/data_categories';
import technologies from '../options/technologies';

const model = {
  title: '',
  description: '',
  category: '',
  technology: '',
  database: '',
  link: '',
};

const form = [
  {
    type: 'text',
    name: 'title',
    label: 'Title',
    placeholder: 'Title',
    component: 'el-input',
    required: true,
  },
  {
    type: 'textarea',
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
    component: 'el-input',
    required: true,
  },
  {
    type: 'select',
    name: 'category',
    label: 'Data category',
    placeholder: 'Data category',
    component: 'el-select',
    required: true,
    options: data_categories,
  },
  {
    type: 'select',
    name: 'technology',
    label: 'Technology',
    placeholder: 'Technology',
    component: 'el-select',
    required: true,
    options: technologies,
  },
  {
    type: 'text',
    name: 'database',
    label: 'Database/Company',
    placeholder: 'Database/Company',
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'link',
    label: 'Link',
    placeholder: 'Link',
    component: 'el-input',
    required: true,
  },
];

const rules = {
  title: [
    {
      type: 'required',
      message: 'Title is required.',
    },
    {
      type: 'string',
      message: 'Title must be a string.',
    },
    {
      type: 'minLength',
      minLength: 10,
      message: 'Title must be at least 3 characters.',
    },
    {
      type: 'maxLength',
      maxLength: 200,
      message: 'Title must be less than 100 characters.',
    },
  ],
  description: [
    {
      type: 'required',
      message: 'Description is required.',
    },
    {
      type: 'minLength',
      minLength: 50,
      message: 'Description must be at least 50 characters.',
    },
    {
      type: 'maxLength',
      maxLength: 700,
      message: 'Description must be less than 500 characters.',
    },
  ],
  category: [
    {
      type: 'required',
      message: 'Please select a category',
    },
  ],
  technology: [
    {
      type: 'required',
      message: 'Please select a technology',
    }
  ],
  database: [
    {
      type: 'required',
      message: 'Database/Company is required.',
    }
  ],
  link: [
    {
      type: 'required',
      message: 'Link is required.',
    }
  ],
};

export { model, form, rules };
